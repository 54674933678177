export const getBundlePricing = ({
  originalPrice,
  discount,
  percent,
  specialPrice,
  mapDetail,
  quantityLimit,
  priceUnitOfMeasure
}) => {
  const { mapPolicy, minAdvertisedPrice, hidePrice } = mapDetail || {};

  let finalOriginalPrice = null;
  let finalValuePrice = null;

  if (!hidePrice) {
    if (specialPrice >= originalPrice) {
      finalValuePrice = specialPrice;
    } else {
      finalOriginalPrice = originalPrice;
      finalValuePrice = specialPrice;
    }
  }

  return {
    alternate: {
      bulk: {
        value: null,
        thresholdQuantity: null
      },
      unit: {
        unitsPerCase: null,
        caseUnitOfMeasure: null,
        unitsOriginalPrice: null,
        value: null
      }
    },
    alternatePriceDisplay: false,
    mapAboveOriginalPrice: minAdvertisedPrice,
    mapDetail: (mapPolicy && { mapPolicy }) || null,
    message: null,
    original: finalOriginalPrice,
    preferredPriceFlag: null,
    promotion: {
      dates: {
        start: null,
        end: null
      },
      dollarOff: discount,
      percentageOff: percent,
      savingsCenter: 0,
      savingsCenterPromos: null,
      specialBuySavings: null,
      specialBuyDollarOff: null,
      specialBuyPercentageOff: null,
      type: 'DISCOUNT'
    },
    specialBuy: null,
    unitOfMeasure: hidePrice ? null : priceUnitOfMeasure,
    value: finalValuePrice,
    someSkusHaveZeroPrice: false,
    quantityLimit
  };
};

export const mockProductsFulfillmentData = [
  {
    __typename: 'BaseProduct',
    itemId: '310554018',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['6586 -InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  __typename: 'Location',
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 9,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'pickup'
        }
      ]
    },
    quantity: 1
  },
  {
    __typename: 'BaseProduct',
    itemId: '203164241',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'boss',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-26',
                startDate: '2024-02-23'
              },
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: null,
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'pickup'
        }
      ]
    },
    quantity: 2
  },
  {
    __typename: 'BaseProduct',
    itemId: '302757312',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'delivery'
        }
      ]
    },
    quantity: 1
  }
];

export const mockProductsFulfillmentQuantityLimit = [
  {
    itemId: '316617970',
    info: {
      quantityLimit: 3
    },
    fulfillment: {
      fulfillmentOptions: [
        {
          type: 'pickup',
          services: [
            {
              type: 'bopis',
              locations: [
                {
                  inventory: {
                    isOutOfStock: false,
                    quantity: 10,
                    isInStock: true,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ]
        },
        {
          type: 'delivery',
          services: [
            {
              type: 'express delivery',
              locations: [
                {
                  inventory: {
                    isOutOfStock: false,
                    quantity: 52,
                    isInStock: true,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    itemId: '325586031',
    info: {
      quantityLimit: 5
    },
    fulfillment: {
      fulfillmentOptions: [
        {
          type: 'pickup',
          services: [
            {
              type: 'boss',
              locations: [
                {
                  inventory: {
                    isOutOfStock: false,
                    quantity: 826,
                    isInStock: true,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ]
        },
        {
          type: 'delivery',
          services: [
            {
              type: 'sth',
              locations: [
                {
                  inventory: {
                    isOutOfStock: false,
                    quantity: 826,
                    isInStock: true,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
];

export const mockProductsFulfillmentOptionMissingData = [
  {
    __typename: 'BaseProduct',
    itemId: '310554018',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: null
    }
  },
  {
    __typename: 'BaseProduct',
    itemId: '203164241',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'boss',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-26',
                startDate: '2024-02-23'
              },
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: null,
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'pickup'
        }
      ]
    }
  },
  {
    __typename: 'BaseProduct',
    itemId: '302757312',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'delivery'
        }
      ]
    }
  }
];

export const mockProductsSthFulfillmentData = [
  {
    __typename: 'BaseProduct',
    itemId: '310554018',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'boss',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-26',
                startDate: '2024-02-23'
              },
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: null,
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 4,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'pickup'
        },
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 50,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'delivery'
        }
      ]
    },
    info: {
      quantityLimit: 2
    }
  },
  {
    __typename: 'BaseProduct',
    itemId: '203164241',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [

        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 15,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'delivery'
        }
      ]
    },
    info: {
      quantityLimit: 0
    }
  },
  {
    __typename: 'BaseProduct',
    itemId: '302757312',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null
                  }
                }
              ]
            }
          ],
          type: 'delivery'
        }
      ]
    },
    info: {
      quantityLimit: 0
    }
  }
];

export const mockedcartItems = [{
  itemId: '320752334',
  fulfillmentLocation: '77429',
  fulfillmentMethod: 'ShipToHome',
  quantity: 1,
  storeId: '6586',
  storeSkuNumber: '1007815143',
  unitPrice: 444.96,
  zipCode: '77429'
}, {
  itemId: '317449611',
  fulfillmentLocation: '77429',
  fulfillmentMethod: 'ShipToHome',
  quantity: 1,
  storeId: '6586',
  storeSkuNumber: '1006484590',
  unitPrice: 499.99,
  zipCode: '77429'
}];

export const mockedCartOptions = {
  alterBrowserHistory: true,
  misship: true,
  channel: 'desktop',
  configurableItems: mockedcartItems,
  fulfillmentMethod: 'ShipToHome',
  storeId: '131',
  storeZip: 77343,
  zipCode: 77343,
  bypassBss: true,
  bss: true,
  directCheckout: true,
  hasConfigurableItems: false,
  component: 'add both'
};

export const mockedOOSproducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GU,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
        {
          fulfillable: true,
          messages: [
            'express delivery',
            'ShipToHome null'
          ],
          services: [
            {
              type: 'express delivery',
              isDefault: true,
              deliveryCharge: '8.99',
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 8.99,
              locations: [
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            },
            {
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'delivery',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      recommendationFlags: {
        visualNavigation: false,
        pipCollections: true,
        packages: false,
        ACC: true,
        collections: false,
        frequentlyBoughtTogether: true,
        bundles: false,
        batItems: true,
        __typename: 'RecommendationFlags'
      },
      quantityLimit: 0,
      __typename: 'Info'
    }
  },
  {
    itemId: '319376783',
    fulfillment: {
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GU,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock',
            '6838 InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 8,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                },
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: 'N. Eldridge',
                  storePhone: '(281)807-5128',
                  state: 'TX',
                  distance: 6.3351912,
                  inventory: {
                    quantity: 3,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
        {
          fulfillable: true,
          messages: [
            'express delivery',
            'ShipToHome null'
          ],
          services: [
            {
              type: 'express delivery',
              isDefault: true,
              deliveryCharge: '8.99',
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 8.99,
              locations: [
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 8,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            },
            {
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                endDate: '2024-03-07',
                startDate: '2024-03-07',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 139,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'delivery',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      recommendationFlags: {
        visualNavigation: false,
        pipCollections: true,
        packages: false,
        ACC: true,
        collections: false,
        frequentlyBoughtTogether: true,
        bundles: false,
        batItems: true,
        __typename: 'RecommendationFlags'
      },
      quantityLimit: 0,
      __typename: 'Info'
    }
  },
  {
    itemId: '316630227',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 4,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
        {
          fulfillable: true,
          messages: [
            'express delivery',
            'ShipToHome null'
          ],
          services: [
            {
              type: 'express delivery',
              isDefault: true,
              deliveryCharge: '8.99',
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 8.99,
              locations: [
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 5,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            },
            {
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 140,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'delivery',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      recommendationFlags: {
        visualNavigation: false,
        pipCollections: true,
        packages: false,
        ACC: true,
        collections: false,
        frequentlyBoughtTogether: true,
        bundles: false,
        batItems: true,
        __typename: 'RecommendationFlags'
      },
      quantityLimit: 0,
      __typename: 'Info'
    }
  }
];

export const mockedNoShipButHasBopisProducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 10,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      quantityLimit: 0
    }
  }
];

export const mockedNoShipButHasNoBopisProducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GA,AK,GU,PR,VI,TX',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GA,AK,GU,PR,VI,TX',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      quantityLimit: 0
    }
  }
];

export const mockedUnavailableProducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GA,AK,GU,PR,VI,TX',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GA,AK,GU,PR,VI,TX',
      fulfillmentOptions: null,
      __typename: 'Fulfillment'
    },
    info: {
      quantityLimit: 0
    }
  }
];

export const mockLaundrySelectableVariantdata = [
  {
    name: 'Agitator/Impeller Type',
    id: '329517654',
    guid: 'b2a7875c-ab07-4ab2-9fb5-521ee16eb183',
    value: 'Agitator',
    __typename: 'BundleSDSkuAttributes'
  },
  {
    name: 'Agitator/Impeller Type',
    id: '329500038',
    guid: 'b2a7875c-ab07-4ab2-9fb5-521ee16eb183',
    value: 'Impeller',
    __typename: 'BundleSDSkuAttributes'
  },
  {
    name: 'Product Width (in.)',
    id: '331145986',
    guid: 'fac03a00-67f9-402b-a121-fb9448fc552d',
    value: '29.875',
    __typename: 'BundleSDSkuAttributes'
  },
  {
    name: 'Dryer Fuel Type',
    id: '312429905',
    guid: 'aacb73e8-7810-4d9e-8f23-e5ac86ef7f3f',
    value: 'Gas',
    __typename: 'BundleSDSkuAttributes'
  },
  {
    name: 'Dryer Fuel Type',
    id: '312440135',
    guid: 'aacb73e8-7810-4d9e-8f23-e5ac86ef7f3f',
    value: 'Electric',
    __typename: 'BundleSDSkuAttributes'
  },
  {
    name: 'Hardware Color/Finish',
    id: '331145986',
    guid: 'c5e35c90-e6ac-47c5-ad8f-bc7d2ec31245',
    value: 'Silver',
    __typename: 'BundleSDSkuAttributes'
  }
];