import dryer from '../components/subcomponents/infoDrawer/dryer';
import laundryAccessory from '../components/subcomponents/infoDrawer/laundry-accessory';
import washer from '../components/subcomponents/infoDrawer/washer';
import {
  EXPERIENCE_TYPE_KITCHEN, EXPERIENCE_TYPE_LAUNDRY,
  DRYER_FUEL_TYPE, LAUNDRY_ACCESSORIES_DRAWER, WASHER_TYPE, KITCHEN_FUEL_TYPE,
  APPLIANCE_TYPE, WASHER, DRYER, GAS, ELECTRIC, AGITATORS, IMPELLER
} from '../constants';

const compareString = (firstString, secondString) => {
  return firstString?.toLowerCase() === secondString?.toLowerCase();
};

const isStringIncludes = (sourceString, patternString) => {
  return sourceString?.toLowerCase()?.includes(patternString?.toLowerCase());
};

export const getSelectableVarientType = (product, varientName) => {
  const features = product?.keyProductFeatures?.keyProductFeaturesItems?.[0]?.features;
  if (!Array.isArray(features) || features.length === 0) {
    return null;
  }
  const featureType = features?.find((feature) => compareString(feature?.name, varientName));

  if (featureType?.value) {
    return featureType?.value;
  }

  return null;
};

export const getSelectableVarientFromTitle = (product, varientName) => {
  const title = product?.identifiers?.productLabel;
  switch (varientName) {
  case KITCHEN_FUEL_TYPE:
    if (isStringIncludes(title, ELECTRIC) || isStringIncludes(title, 'induction')) {
      return ELECTRIC;
    }
    if (isStringIncludes(title, GAS)) {
      return GAS;
    }
    return null;
  case WASHER_TYPE:
    if (isStringIncludes(title, AGITATORS)) {
      return AGITATORS;
    }
    if (isStringIncludes(title, IMPELLER)) {
      return IMPELLER;
    }
    return null;
  case DRYER_FUEL_TYPE:
    if (isStringIncludes(title, GAS)) {
      return GAS;
    }
    if (isStringIncludes(title, ELECTRIC)) {
      return ELECTRIC;
    }
    return null;
  default:
    return null;
  }
};

export const getSelectableVariants = (selectableVariants, varientName) => {

  const variants = Array.from(
    new Set(selectableVariants)
  )?.filter((variant) => compareString(variant?.name, varientName));

  if (!variants?.length) return null;

  return variants;
};

const getApplicanceType = (product) => {
  const title = product?.identifiers?.productLabel;
  const applianceType = getSelectableVarientType(product, APPLIANCE_TYPE);
  if (isStringIncludes(applianceType, WASHER) || isStringIncludes(title, WASHER)) {
    return WASHER;
  }
  if (isStringIncludes(applianceType, DRYER) || isStringIncludes(title, DRYER)) {
    return DRYER;
  }
  return null;
};

export const getVarientName = (experienceType, product) => {
  const applianceType = getApplicanceType(product);
  switch (experienceType) {
  case EXPERIENCE_TYPE_KITCHEN:
    return KITCHEN_FUEL_TYPE;
  case EXPERIENCE_TYPE_LAUNDRY:
    if (compareString(applianceType, WASHER)) { return WASHER_TYPE; }
    if (compareString(applianceType, DRYER)) { return DRYER_FUEL_TYPE; }
    return '';
  default:
    return '';
  }
};

export const getDrawerInfo = (varientName) => {
  switch (varientName) {
  case DRYER_FUEL_TYPE:
    return dryer;
  case LAUNDRY_ACCESSORIES_DRAWER:
    return laundryAccessory;
  case WASHER_TYPE:
    return washer;
  default:
    return {};
  }
};

export const getVariantGroup = (selectableVariantsData, position = 0) => {
  const variantGroup = Object.entries(selectableVariantsData || {})?.[position] || [];
  const [variantName, variants] = variantGroup;

  return { variantName, variants };
};

export const isProductInVariantGroup = ({ productId, variants }) => {
  if (!productId || !variants) return false;
  return variants?.some((variant) => variant?.id === productId);
};