import React from 'react';
import { string, node } from 'prop-types';
import { Typography, Link, Card } from '@one-thd/sui-atomic-components';

export const WhatWeOfferItem = ({
  description,
  titleLine1,
  titleLine2,
  Icon,
  linkUrl,
  linkText,
  target
}) => {

  return (
    <div className="sui-flex">
      <Card
        PaperProps={{
          className: "sui-flex sui-flex-grow"
        }}
        CardContentProps={{
          grow: true
        }}
      >
        <div className="sui-flex sui-flex-col sui-grow sm:sui-min-w-full">
          <div className="sui-flex sui-flex-row sui-mb-4">
            <div className="sui-my-auto">
              <Icon size="large" />
            </div>
            <div className="sui-ml-4">
              <Typography variant="h4">{titleLine1}</Typography>
              <Typography variant="h4">{titleLine2}</Typography>
            </div>
          </div>
          <div className="sui-flex sui-flex-col sui-grow sui-justify-between">
            <div className="sui-mb-4">
              {description}
            </div>
            <Link href={linkUrl} target={target}>
              {linkText}
            </Link>
          </div>
        </div>

      </Card>

    </div>
  );
};

WhatWeOfferItem.propTypes = {
  description: string.isRequired,
  titleLine1: string.isRequired,
  titleLine2: string.isRequired,
  Icon: node.isRequired,
  linkUrl: string.isRequired,
  linkText: string.isRequired,
  target: string.isRequired
};
