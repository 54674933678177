import {
  params,
  bool,
  shape,
  string
} from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      displayWhatWeOffer: bool()
    })
  })
};
