/* eslint-disable max-len */
import { Apron, Secure, Returns, Savings } from '@one-thd/sui-icons';

export const CONTENT_CARDS = [
  {
    description: 'We offer move or haul away services, and installation on most appliances when you purchase required parts.',
    titleLine1: 'Delivery',
    titleLine2: 'Services',
    Icon: Apron,
    linkUrl: '/c/Appliance_Delivery_Installation',
    linkText: 'Installation Requirements',
    target: '_blank'
  },
  {
    description: 'Get 24/7 support and our 3-day Service Guarantee',
    titleLine1: 'The Home Depot',
    titleLine2: 'Protection Plan',
    Icon: Secure,
    linkUrl: '/c/Home_Depot_Protection_Plan_Coverage_Details#majorappliances',
    linkText: 'Plan Coverage and Details',
    target: '_blank'
  },
  {
    description: 'You can return your appliance for damages and defects within 48 hours.',
    titleLine1: '48-Hour',
    titleLine2: 'Return Policy',
    Icon: Returns,
    linkUrl: '/c/Return_Policy',
    linkText: 'Return Policy',
    target: '_blank'
  },
  {
    description: 'We will match prices including shipping of any other online competitor.',
    titleLine1: 'Price Match',
    titleLine2: 'Guarantee',
    Icon: Savings,
    linkUrl: '/c/PM_New_Lower_Price',
    linkText: 'Learn More',
    target: '_blank'
  }
];
