import React, { useEffect } from 'react';
import { shape, string, bool } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { WhatWeOfferPlaceholder } from './WhatWeOfferPlaceholder';
import { WhatWeOfferItem } from './WhatWeOfferItem';
import { dataModel } from '../dataModel';
import { CONTENT_CARDS } from '../constants';

export const WhatWeOffer = ({ itemId, productData }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('what-we-offer.ready');
  }, []);

  const isValidProductData = typeof productData?.info?.displayWhatWeOffer === 'boolean';

  const { error, loading, data } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: isValidProductData
  });

  const displayWhatWeOffer = isValidProductData
    ? productData?.info?.displayWhatWeOffer
    : data?.product?.info?.displayWhatWeOffer;

  if (!loading && !error && !displayWhatWeOffer) {
    return null;
  }

  return (
    <div
      className="sui-flex sui-flex-col sui-w-auto sui-m-4 lg:sui-px-0"
      data-component="WhatWeOffer"
    >
      {loading ? (
        <WhatWeOfferPlaceholder />
      ) : (
        <>
          <div className="sui-mb-4">
            <Typography variant="h2">
              What We Offer
            </Typography>
          </div>
          <div className="sui-grid sm:sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-4 sui-gap-8">
            {CONTENT_CARDS.map(({
              description,
              titleLine1,
              titleLine2,
              Icon,
              linkUrl,
              linkText,
              target
            }) => (
              <WhatWeOfferItem
                key={`what-we-offer-item-${titleLine1}-${titleLine2}`}
                description={description}
                titleLine1={titleLine1}
                titleLine2={titleLine2}
                Icon={Icon}
                linkUrl={linkUrl}
                linkText={linkText}
                target={target}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

WhatWeOffer.propTypes = {
  itemId: string.isRequired,
  productData: shape({
    info: shape({
      displayWhatWeOffer: bool
    })
  })
};

WhatWeOffer.defaultProps = {
  productData: null
};

WhatWeOffer.dataModel = dataModel;

WhatWeOffer.displayName = 'WhatWeOffer';
